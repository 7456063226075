<template>
    <div class="talentsApply">
        <div v-html="cbk.content" style="width:96%;margin-top:20px;padding-bottom:30px;" class="marginAuto"></div>
    </div>
</template>
<script>
import { commonPrivacy } from '@/utils/Api/commonList'
export default {
    name:'talentsApply',
    data(){
        return{
            cbk:{}
        }
    },
    created(){
        this.initial()
    },
    methods:{
        async initial(){
            let cbk = await commonPrivacy({type: 6})
            this.cbk = cbk
        }
    }
}
</script>
<style lang="scss" scoped>
.talentsApply{

}
</style>